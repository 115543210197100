import { BlockUI } from 'primereact/blockui';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { confirmPopup } from 'primereact/confirmpopup';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { Toast } from 'primereact/toast';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import SearchVocabulariesModal from '../components/dialogs/SearchVocabulariesModal';
import VocabularyModal from '../components/forms/Vocabularies/VocabularyModal';
import { ROLE_ADMIN } from '../data/roles';
import { getLanguages } from '../services/languages';
import {
  createVocabulary,
  deleteVocabulary,
  getVocabularies,
  getVocabulary,
  publishVocabulary,
  updateVocabulary,
} from '../services/vocabularies';
import { UserContext } from '../store/user';
import { handleError } from '../utilities/errors';

const Vocabularies = () => {
  const [blockedPanel, setBlockedPanel] = useState(true);
  const [vocs, setVocs] = useState([]);
  const [vocModalVisible, setVocModalVisible] = useState(false);
  const [selectedVocabulary, setSelectedVocabulary] = useState(null);
  const [searchModalVisible, setSearchModalVisible] = useState(false);
  const [languages, setLanguages] = useState([]);
  const { role } = useContext(UserContext);
  const [tableSearch, setTableSearch] = useState('');

  const toast = useRef(null);

  const sortedVocs = vocs.sort((a, b) => a.listname.localeCompare(b.listname)) || [];

  const fetchVocabularies = useCallback(async () => {
    try {
      setBlockedPanel(true);
      const { data } = await getVocabularies();
      setVocs(data);
    } catch (e) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Could not fetch choice lists. Please try again.',
      });
    } finally {
      setBlockedPanel(false);
    }
  }, []);

  const fetchVocabulary = useCallback(async (id, shouldOpenModal = true) => {
    try {
      setBlockedPanel(true);
      const { data } = await getVocabulary(id);
      setSelectedVocabulary(data);
      if (shouldOpenModal) {
        setVocModalVisible(true);
      }
    } catch (e) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Could not fetch single choice list. Please try again.',
      });
    } finally {
      setBlockedPanel(false);
    }
  }, []);

  const handlePublishVocabulary = useCallback(
    async (vocId) => {
      try {
        setBlockedPanel(true);
        await publishVocabulary(vocId);
      } catch (e) {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: handleError(e),
        });
      } finally {
        await fetchVocabularies();
      }
    },
    [fetchVocabularies]
  );

  const fetchLanguages = async () => {
    try {
      const { data } = await getLanguages();
      setLanguages(data || []);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCreateVocabulary = useCallback(
    async (voc) => {
      try {
        setBlockedPanel(true);
        await createVocabulary(voc.body);
      } catch (e) {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: handleError(e),
        });
      } finally {
        await fetchVocabularies();
      }
    },
    [fetchVocabularies]
  );

  const handleUpdateVocabulary = useCallback(
    async (voc) => {
      try {
        setBlockedPanel(true);
        await updateVocabulary(voc.id, voc.body);
      } catch (e) {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: handleError(e),
        });
      } finally {
        await fetchVocabularies();
      }
    },
    [fetchVocabularies]
  );

  const handleDeleteVocabulary = useCallback(
    async (id) => {
      try {
        setBlockedPanel(true);
        await deleteVocabulary(id);
      } catch (e) {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Could not delete choice list. Please try again.',
        });
      } finally {
        await fetchVocabularies();
      }
    },
    [fetchVocabularies]
  );

  useEffect(() => {
    fetchVocabularies();
    fetchLanguages();
  }, []); // eslint-disable-line

  const tableHeader = () => (
    <div className="p-d-flex p-jc-end">
      <span className="p-input-icon-right">
        <i className="pi pi-search" />
        <InputText
          value={tableSearch}
          onChange={(e) => setTableSearch(e.target.value)}
          placeholder="Filter"
        />
      </span>
    </div>
  );

  const vocsActionsTemplate = (rowData) => (
    <div className="p-text-right">
      <Button
        icon="fa-duotone fa-pen-to-square"
        className="p-button-success p-mr-2"
        tooltip="Edit choice list"
        tooltipOptions={{ position: 'top' }}
        onClick={() => fetchVocabulary(rowData.id)}
      />
      {role === ROLE_ADMIN && !rowData.isGlobal && (
        <Button
          icon="fa-duotone fa-upload"
          className="p-button-info p-mr-2"
          tooltip="Publish choice list"
          tooltipOptions={{ position: 'top' }}
          onClick={(e) => {
            confirmPopup({
              target: e.currentTarget,
              message: 'Are you sure you want to publish this choice list?',
              icon: 'pi pi-exclamation-triangle',
              accept: () => handlePublishVocabulary(rowData.id),
            });
          }}
        />
      )}
      <Button
        icon="fa-duotone fa-trash"
        tooltip="Delete choice list"
        tooltipOptions={{ position: 'top' }}
        className="p-button-danger"
        onClick={(e) => {
          confirmPopup({
            target: e.currentTarget,
            message: 'Are you sure you want to delete this choice list?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => handleDeleteVocabulary(rowData.id),
          });
        }}
      />
    </div>
  );

  return (
    <div style={{ paddingBottom: '56px' }}>
      <Toast ref={toast} />
      <BlockUI blocked={blockedPanel}>
        <Panel header={<div className="p-ml-2">My Choice Lists</div>} toggleable className="p-mb-4">
          <div className="p-text-right">
            <div className="p-mb-3">
              <Button
                icon="fa-duotone fa-plus"
                className="p-button-success p-mr-2"
                label="New Choice List"
                onClick={() => {
                  setSelectedVocabulary(null);
                  setVocModalVisible(true);
                }}
              />
              <Button
                icon="fa-duotone fa-magnifying-glass"
                className="p-button-info"
                label="Search"
                tooltip={`Search for "agronomy" to see about 50 relevant choice lists. You can import any or all to create answer choices for questions in your questionnaire.
`}
                tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
                onClick={() => setSearchModalVisible(true)}
              />
            </div>
            <DataTable
              paginator
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport"
              currentPageReportTemplate="Total records: {totalRecords}"
              header={tableHeader}
              globalFilter={tableSearch}
              globalFilterFields={['listname', 'description']}
              rows={20}
              rowsPerPageOptions={[20, 50, 100]}
              rowHover
              emptyMessage="No choice lists have been defined."
              value={sortedVocs}
            >
              <Column
                field="listname"
                header="Name"
                body={({ listname }) => <span>{listname}</span>}
              />
              <Column header="Description" field="description" />
              <Column
                style={{ minWidth: '250px' }}
                header="Actions"
                alignHeader="right"
                body={vocsActionsTemplate}
              />
            </DataTable>
          </div>
        </Panel>
      </BlockUI>
      <VocabularyModal
        languages={languages}
        internalId={selectedVocabulary?.id}
        vocabulary={selectedVocabulary?.body}
        onCreate={handleCreateVocabulary}
        onUpdate={handleUpdateVocabulary}
        visible={vocModalVisible}
        setVisible={setVocModalVisible}
      />
      <SearchVocabulariesModal
        toast={toast}
        visible={searchModalVisible}
        setVisible={setSearchModalVisible}
        onVocabulariesRefresh={() => fetchVocabularies()}
      />
    </div>
  );
};

export default Vocabularies;
