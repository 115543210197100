import { Dialog } from 'primereact/dialog';
import React from 'react';

const PreviewQuestionnaireDialog = ({ url, dialogOpen, setDialogOpen }) => (
  <Dialog
    header="Preview Questionnaire"
    visible={dialogOpen}
    style={{ width: '600px' }}
    draggable={false}
    closable
    maximizable
    modal
    onHide={() => setDialogOpen(false)}
  >
    <div className="p-fluid">
      <iframe src={url} title="Preview Questionnaire" width={550} />
    </div>
  </Dialog>
);

export default PreviewQuestionnaireDialog;
