import { BlockUI } from 'primereact/blockui';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { confirmPopup } from 'primereact/confirmpopup';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { Toast } from 'primereact/toast';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import SearchBlocksModal from '../components/dialogs/SearchBlocksModal';
import { ROLE_ADMIN } from '../data/roles';
import { deleteBlock, getBlocks, publishBlock } from '../services/blocks';
import { UserContext } from '../store/user';

const Blocks = () => {
  const [blockedPanel, setBlockedPanel] = useState(true);
  const [blocks, setBlocks] = useState([]);
  const [searchModalVisible, setSearchModalVisible] = useState(false);
  const { role } = useContext(UserContext);
  const [tableSearch, setTableSearch] = useState('');

  const sortedBlocks = blocks.sort((a, b) => a.name.localeCompare(b.name)) || [];

  const toast = useRef(null);

  const fetchBlocks = useCallback(async () => {
    try {
      setBlockedPanel(true);
      const { data } = await getBlocks();
      setBlocks(data);
    } catch (e) {
      toast.current.show({
        severity: 'error',
        summary: 'Something went wrong',
        detail: 'Could not fetch blocks. Please try again.',
      });
    } finally {
      setBlockedPanel(false);
    }
  }, []);

  const handlePublishBlock = async (id) => {
    try {
      setBlockedPanel(true);
      await publishBlock(id);
    } catch (e) {
      toast.current.show({
        severity: 'error',
        summary: 'Something went wrong',
        detail: 'Could not publish block. Please try again.',
      });
    } finally {
      await fetchBlocks();
    }
  };

  const handleDeleteBlock = async (id) => {
    try {
      setBlockedPanel(true);
      await deleteBlock(id);
    } catch (e) {
      toast.current.show({
        severity: 'error',
        summary: 'Something went wrong',
        detail: 'Could not delete block. Please try again.',
      });
    } finally {
      await fetchBlocks();
    }
  };

  useEffect(() => {
    fetchBlocks();
  }, []); // eslint-disable-line

  const blocksActionTemplate = (rowData) => (
    <div className="p-text-right">
      {role === ROLE_ADMIN && !rowData.isGlobal && (
        <Button
          icon="fa-duotone fa-upload"
          className="p-button-info p-mr-2"
          tooltip="Publish block"
          tooltipOptions={{ position: 'top' }}
          onClick={(e) =>
            confirmPopup({
              target: e.currentTarget,
              message: 'Are you sure you want to publish this block?',
              icon: 'pi pi-exclamation-triangle',
              accept: () => handlePublishBlock(rowData.id),
            })
          }
        />
      )}
      <Button
        icon="fa-duotone fa-trash"
        tooltip="Delete block"
        tooltipOptions={{ position: 'top' }}
        className="p-button-danger"
        onClick={(e) =>
          confirmPopup({
            target: e.currentTarget,
            message: 'Are you sure you want to delete this block?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => handleDeleteBlock(rowData.id),
          })
        }
      />
    </div>
  );

  const tableHeader = () => (
    <div className="p-d-flex p-jc-end">
      <span className="p-input-icon-right">
        <i className="pi pi-search" />
        <InputText
          value={tableSearch}
          onChange={(e) => setTableSearch(e.target.value)}
          placeholder="Filter"
        />
      </span>
    </div>
  );

  return (
    <div style={{ paddingBottom: '56px' }}>
      <Toast ref={toast} />
      <BlockUI blocked={blockedPanel}>
        <Panel header={<div className="p-ml-2">My Blocks</div>} className="p-mb-4">
          <div className="p-text-right">
            <div className="p-mb-3">
              <Button
                icon="fa-duotone fa-magnifying-glass"
                className="p-button-info"
                label="Search"
                onClick={() => setSearchModalVisible(true)}
              />
            </div>
            <DataTable
              paginator
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport"
              currentPageReportTemplate="Total records: {totalRecords}"
              header={tableHeader}
              globalFilter={tableSearch}
              globalFilterFields={['name', 'description']}
              rows={20}
              rowsPerPageOptions={[20, 50, 100]}
              rowHover
              emptyMessage="No blocks have been defined."
              value={sortedBlocks}
            >
              <Column header="Name" field="name" />
              <Column header="Description" field="description" />
              <Column
                style={{ minWidth: '250px' }}
                header="Actions"
                alignHeader="right"
                body={blocksActionTemplate}
              />
            </DataTable>
          </div>
        </Panel>
      </BlockUI>
      <SearchBlocksModal
        toast={toast}
        visible={searchModalVisible}
        setVisible={setSearchModalVisible}
        onBlocksRefresh={fetchBlocks}
      />
    </div>
  );
};

export default Blocks;
