/* eslint-disable react/react-in-jsx-scope */
const Logo = ({ variant, size = 'normal' }) => (
  <div className="p-text-center">
    <div className="p-d-flex p-jc-center p-ai-center">
      {size === 'normal' && <h1 style={{ color: variant || 'black' }}>DataScribe</h1>}
      {size === 'small' && <h3 style={{ color: variant || 'black' }}>DataScribe</h3>}
    </div>
  </div>
);

export default Logo;
