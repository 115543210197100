import { AutoComplete } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Message } from 'primereact/message';
import { useEffect, useState } from 'react';
import { getUnits } from '../../../services/units';
import { useDebounce } from '../../../utilities/hooks';
import RequiredField from '../../RequiredField';
import TermHelperDialogForm from '../TermHelper/TermHelperDialogForm';

const Type = ({
  enabled,
  isEditable,
  hasUnit = false,
  typeSelect,
  typeDropdownValues,
  valueUnits,
  setValueUnits,
  suggestedUnits,
  setSuggestedUnits,
  unitTemplate,
  updateOutput,
  requiredFields,
  questionId,
}) => {
  const unitlessValue = {
    description: 'A quantity that has no units',
    term: 'Scalar quantity (pure number)',
    id: 'http://data.scio.systems/kos/dsbo/unit/0001',
    scheme: 'dataSCRIBE Base Ontology (DSBO)',
  };

  const [unitSearch, setUnitSearch] = useState('');
  const [unitless, setUnitless] = useState(valueUnits && valueUnits.id === unitlessValue.id);
  const [termHelperVisible, setTermHelperVisible] = useState(false);
  const debouncedUnitSearch = useDebounce(unitSearch, 100);
  const tooltips = {
    location:
      'Type of location information to collect. A path may be open-ended, while a shape will be closed automatically to form a polygon.',
  };

  useEffect(() => {
    if (unitSearch.length > 0 && !unitless) {
      getUnits(debouncedUnitSearch)
        .then(({ data }) => setSuggestedUnits(data || []))
        .catch(console.error);
    }
  }, [debouncedUnitSearch, setSuggestedUnits, unitSearch.length, unitless]);

  if (!enabled) {
    return null;
  }

  return (
    <>
      <div className="p-col-12 p-md-6">
        <div className="custom-label">
          <Button
            label="Type"
            tooltip={tooltips[questionId] || ''}
            tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
          />
        </div>
        <Dropdown
          id="type"
          disabled={!isEditable}
          value={typeSelect}
          onChange={(e) => updateOutput(e.target)}
          options={typeDropdownValues}
          optionLabel="name"
          placeholder="Select"
        />
        {requiredFields.includes('type') && typeSelect?.length === 0 && (
          <Message
            className="p-mt-2 p-text-left custom"
            severity="error"
            text="The type field is mandatory."
          />
        )}
      </div>
      {hasUnit && (
        <div className="p-col-12 p-md-6">
          <div className="custom-label">
            <Button>
              <RequiredField />
              Unit
            </Button>
          </div>
          <div className="p-inputgroup">
            <AutoComplete
              id="unit"
              value={valueUnits}
              field="term"
              suggestions={suggestedUnits}
              completeMethod={(e) => setUnitSearch(e.query)}
              placeholder="Please start typing..."
              itemTemplate={unitTemplate}
              onChange={(e) => updateOutput(e.target)}
            />
          </div>
          {requiredFields.includes('unit') && valueUnits?.length === 0 && (
            <Message
              className="p-mt-2 p-text-left custom"
              severity="error"
              text="The units field is mandatory."
            />
          )}
          <div style={{ marginTop: '12px' }} className="p-grid p-formgrid">
            <div className="p-col-12 p-md-6 p-d-flex p-ai-center">
              <Checkbox
                inputId="unitless"
                tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
                onChange={(e) => {
                  setUnitless(e.checked);
                  if (e.checked) {
                    setValueUnits(unitlessValue);
                    updateOutput({ id: 'unit', value: unitlessValue });
                  } else {
                    setValueUnits(undefined);
                  }
                }}
                checked={unitless}
              />
              <label htmlFor="unitless" className="p-checkbox-label p-ml-2">
                Is unitless
              </label>
            </div>
            <div className="p-col-12 p-md-6 p-text-right">
              <Button
                disabled={!isEditable}
                label="Use custom unit"
                className="p-button-sm"
                icon="fa-duotone fa-input-text"
                style={{ width: 'auto' }}
                onClick={() => setTermHelperVisible(!termHelperVisible)}
              />
              <TermHelperDialogForm
                visible={termHelperVisible}
                vocabulary="unit"
                setVisible={setTermHelperVisible}
                onCreatedTerm={(term) => {
                  setValueUnits(term);
                  updateOutput({ id: 'unit', value: term });
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Type;
