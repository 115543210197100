import { AutoComplete } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { authProviders } from '../../data/auth-providers';
import { transferQuestionnaireOwnership } from '../../services/questionnaires';
import { IDENTITY_PROVIDER_LOCAL, searchUsers } from '../../services/users';
import { ToastContext } from '../../store';
import { handleError } from '../../utilities/errors';
import { useDebounce } from '../../utilities/hooks';

const TransferOwnershipDialog = ({ questionnaireId, dialogOpen, setDialogOpen, handleResult }) => {
  const { t } = useTranslation();
  const { setError } = useContext(ToastContext);
  const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 300);

  useEffect(() => {
    if (search?.length > 0) {
      searchMembers();
    }
  }, [debouncedSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSearch = ({ query }) => setSearch(query);

  const transferOwnership = async (e) => {
    e.preventDefault();
    try {
      await transferQuestionnaireOwnership(questionnaireId, selectedMember.id);
      handleResult({
        severity: 'info',
        summary: 'New ownership has been confirmed',
        detail: '',
        life: 3000,
      });
    } catch (err) {
      handleResult({
        severity: 'error',
        summary: 'There was an error trying to transfer ownership',
        detail: '',
        life: 3000,
      });
    }
  };

  const searchMembers = async () => {
    try {
      const { data: foundMembers } = await searchUsers(search);
      setMembers(foundMembers || []);
    } catch (error) {
      setError(handleError(error));
    }
  };

  const convertIdpToText = (idp) =>
    authProviders?.filter(({ value }) => value === idp)?.pop()?.label || 'N/A';

  const itemTemplate = ({ firstname, lastname, identity_provider: idp, email }) => {
    if (idp === IDENTITY_PROVIDER_LOCAL) {
      return `${firstname} ${lastname} (${email})`;
    }
    return `${firstname} ${lastname} (${convertIdpToText(idp)})`;
  };

  return (
    <Dialog
      header="Transfer ownership of this questionnaire"
      visible={dialogOpen}
      style={{ width: '500px' }}
      draggable={false}
      closable
      maximizable
      modal
      onHide={() => setDialogOpen(false)}
    >
      <div className="p-fluid">
        <form>
          <div className="p-formgrid p-grid">
            <div className="p-col-12">
              <div className="p-field">
                <label htmlFor="members">{t('SEARCH_MEMBERS_BY_NAME')}</label>
                <AutoComplete
                  value={selectedMember}
                  suggestions={members}
                  completeMethod={onSearch}
                  itemTemplate={itemTemplate}
                  selectedItemTemplate={itemTemplate}
                  onChange={(e) => setSelectedMember(e.value)}
                />
              </div>
            </div>
            <div className="p-col-12 p-text-center p-mt-3">
              <div className="p-d-inline-flex p-col-6 p-ai-center p-jc-center">
                <Button
                  label="Transfer ownership"
                  icon="pi pi-send"
                  type="submit"
                  onClick={transferOwnership}
                  disabled={selectedMember === null}
                  className="p-mr-2 p-mb-2"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default TransferOwnershipDialog;
