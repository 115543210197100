import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';

const Calculation = ({ enabled, isEditable, valueCalculation, updateOutput, questionId }) => {
  if (!enabled) {
    return null;
  }

  return (
    <div className="p-col-12">
      <div className="p-inputgroup">
        {questionId !== 'calculation' && (
          <Tooltip target=".calculation-tooltip">
            Use question values in a formula to automatically generate additional data from your
            survey.
            <br /> (click for more information)
          </Tooltip>
        )}
        <Button
          className="calculation-tooltip"
          label="Calculation"
          onClick={() => {
            if (questionId !== 'calculation') {
              window.open(
                'https://docs.getodk.org/form-logic/#calculations',
                '_blank',
                'noopener,noreferrer'
              );
            }
          }}
        />
        <InputText
          disabled={!isEditable}
          value={valueCalculation}
          id="calculation"
          placeholder=""
          onChange={(e) => updateOutput(e.target)}
        />
      </div>
    </div>
  );
};

export default Calculation;
