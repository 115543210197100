import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

const Trigger = ({ enabled, isEditable, valueTrigger, updateOutput }) => {
  if (!enabled) {
    return null;
  }

  return (
    <div className="p-col-12 p-md-12">
      <div className="p-inputgroup">
        <Button
          className="trigger-tooltip"
          label="Trigger"
          tooltip="Calculations can be triggered when a value not involved in the calculation changes (click for more information)"
          onClick={() =>
            window.open(
              'https://docs.getodk.org/form-logic/#triggering-calculations-on-value-change'
            )
          }
          tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
        />
        <InputText
          disabled={!isEditable}
          value={valueTrigger}
          id="trigger"
          placeholder=""
          onChange={(e) => updateOutput(e.target)}
        />
      </div>
    </div>
  );
};

export default Trigger;
