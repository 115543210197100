const t = {
  translation: {
    LOGIN_WITH: 'Login with',
    LOGIN_BUTTON_TEXT: 'Login',
    SIGN_UP_LINK_TEXT: 'Sign up',
    NEW_USER_REGISTRATION_TEXT: 'New User Registration',
    FIRSTNAME: 'First name',
    LASTNAME: 'Last name',
    TERMS_ACCEPT: 'I accept the terms and conditions',
    EMAIL: 'Email',
    REPEAT_EMAIL: 'Repeat email',
    PASSWORD: 'Password',
    REPEAT_PASSWORD: 'Repeat password',
    REPEAT_NEW_PASSWORD: 'Repeat new password',
    CHANGE_PASSWORD: 'Change password',
    CREATE_ACCOUNT_BUTTON: 'Create account',
    USER_PROFILE_TITLE: 'User Profile',
    PROFILE_PICTURE_TITLE: 'Profile Picture',
    USER_INTERFACE_PREFERENCES_TITLE: 'User Interface Preferences',
    UI_LANGUAGE_TITLE: 'UI Language',
    SELECT_UI_LANGUAGE: 'Select UI Language',
    UI_LANGUAGES_DISPLAY: 'How languages are displayed',
    SELECT_UI_LANGUAGE_DISPLAY: 'ISO Language name',
    UI_DATES_DISPLAY: 'How dates are displayed',
    SELECT_UI_DATES_DISPLAY: 'YYYY-MM-DD',
    LOGGED_IN_AS: 'Logged in as:',
    SIGN_OUT: 'Sign Out',
    DASHBOARD: 'Dashboard',
    MY_PROJECTS: 'My Projects',
    CREATE_NEW: 'Create New',
    NEW_PROJECT: 'New Project',
    CREATE_PROJECT: 'Create Project',
    ACCOUNT_SETTINGS: 'Account Settings',
    NAME: 'Name',
    OLD_PASSWORD: 'Old Password',
    NEW_PASSWORD: 'New Password',
    ACCEPT_INVITE: 'Accept Invite',
    REJECT_INVITE: 'Reject Invite',
    CHANGE_PICTURE: 'Change Picture',
    SAVE_CHANGES: 'Save Changes',
    SAVE_CONTINUE: 'Save & Continue',
    FIELD_IS_REQUIRED: 'This field is required.',
    EDIT_PROJECT: 'Edit Project',
    PROJECT_DETAILS: 'Project Details',
    PROJECT_DETAILS_SUBTITLE: 'Basic information for the new project',
    PROJECT_TITLE: 'Project Title',
    PROJECT_ACRONYM: 'Project Acronym',
    PROJECT_DESCRIPTION: 'Project Description',
    INVITE_MEMBERS_TO_PROJECT: 'Invite members to project',
    SEARCH_MEMBERS_BY_NAME: 'Search members by their name',
    SEND_INVITES: 'Send Invites',
    INVITED_TEXT: 'You were invited to join the team',
    BY: 'by',
    REGION_OF_INTEREST: 'Region of Interest',
    SELECT_COUNTRY: 'Select Country',
    DEFINE_THE_ROI: 'Define the ROI',
    NEXT: 'Next',
    PREVIOUS: 'Previous',
    ACTIVE_PROJECT: 'Active Project',
    COUNTRY_LEVEL: 'Country Level',
    UNCCD_ANNEX: 'UNCCD Annex',
    RISK_PROFILES: 'Risk Profiles',
  },
};

export default t;
