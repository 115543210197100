import { Button } from 'primereact/button';
import { InputMask } from 'primereact/inputmask';
import { MultiStateCheckbox } from 'primereact/multistatecheckbox';
import React from 'react';

const Calendar = ({
  enabled,
  isEditable,
  minInclusive,
  minOptions,
  dateFrom,
  maxInclusive,
  maxOptions,
  dateTo,
  updateOutput,
}) => {
  if (!enabled) {
    return null;
  }

  return (
    <>
      <div className="p-col-12 p-md-6">
        <div className="p-inputgroup">
          <Button label="From" />
          <span className="p-inputgroup-addon">
            <MultiStateCheckbox
              id="min_from_inclusive"
              value={minInclusive}
              options={minOptions}
              disabled={!isEditable}
              optionValue="value"
              tooltip={`Parentheses "(" or ")" means that value is excluded, whereas brackets "[" or "]" mean that value is included.`}
              tooltipOptions={{ position: 'bottom' }}
              onChange={(e) => updateOutput(e.target)}
            />
          </span>
          <InputMask
            keyfilter="alpha"
            id="range_date_from"
            mask="9999-99-99"
            value={dateFrom}
            disabled={!isEditable}
            slotChar="yyyy-mm-dd"
            onChange={(e) => updateOutput(e.target)}
          />
        </div>
      </div>
      <div className="p-col-12 p-md-6">
        <div className="p-inputgroup">
          <Button label="To" />
          <InputMask
            id="range_date_to"
            mask="9999-99-99"
            value={dateTo}
            disabled={!isEditable}
            slotChar="yyyy-mm-dd"
            onChange={(e) => updateOutput(e.target)}
          />
          <span className="p-inputgroup-addon">
            <MultiStateCheckbox
              id="max_to_inclusive"
              value={maxInclusive}
              disabled={!isEditable}
              options={maxOptions}
              optionValue="value"
              tooltip={`Parentheses "(" or ")" means that value is excluded, whereas brackets "[" or "]" mean that value is included.`}
              tooltipOptions={{ position: 'bottom' }}
              onChange={(e) => updateOutput(e.target)}
            />
          </span>
        </div>
      </div>
    </>
  );
};

export default Calendar;
