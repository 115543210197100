import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';

const Repeating = ({
  enabled,
  isEditable,
  repeating,
  repeatCount,
  setRepeating,
  setRepeatCount,
}) => {
  if (!enabled) {
    return null;
  }

  return (
    <>
      <div className="p-col-12 p-md-12">
        <Checkbox
          inputId="repeating"
          checked={repeating}
          disabled={!isEditable}
          onChange={(e) => setRepeating(e.checked)}
        />
        <label htmlFor="repeating" style={{ paddingLeft: '10px' }}>
          Loop
        </label>
        <div className="p-d-flex p-pt-2">
          <strong>*</strong> Allows to repeat the group of questions. If selected, the enumerators
          will be prompted after each cycle if they wish to add another entry.
        </div>
      </div>
      {repeating && (
        <div className="p-col-12 p-md-12">
          <div className="p-inputgroup">
            <Button
              onClick={() =>
                window.open(
                  'https://docs.getodk.org/form-logic/#controlling-the-number-of-repetitions',
                  '_blank',
                  'noopener,noreferrer'
                )
              }
              label="Repeat Count"
              style={{ cursor: 'pointer' }}
              tooltip="Define how many times the questions in this Group will be repeated. (click for more information)."
              tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            />
            <InputText
              id="repeat_count"
              disabled={!isEditable}
              value={repeatCount}
              onChange={(e) => setRepeatCount(e.target.value)}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Repeating;
