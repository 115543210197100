import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { Message } from 'primereact/message';
import { MultiStateCheckbox } from 'primereact/multistatecheckbox';

const Range = ({
  enabled,
  typeSelect,
  isEditable,
  minInclusive,
  minOptions,
  updateOutput,
  valueMin,
  valueMax,
  styleSelect,
  sliderValues,
  maxInclusive,
  maxOptions,
}) => {
  if (!enabled) {
    return null;
  }

  const maxValueEmpty = valueMax?.length === 0 || valueMax === undefined || valueMax === '';
  const minValueEmpty = valueMin?.length === 0 || valueMin === undefined || valueMin === '';

  const maxFractionDigits = typeSelect?.name?.toLowerCase() === 'decimal' ? 2 : 0;

  return (
    <>
      <div className="p-col-12 p-md-6">
        <div className="p-inputgroup">
          <Button label="Min Value" />
          <span className="p-inputgroup-addon">
            <MultiStateCheckbox
              disabled={!isEditable}
              id="min_inclusive"
              value={minInclusive}
              options={minOptions}
              optionValue="value"
              tooltip={`Parentheses "(" or ")" means that value is excluded, whereas brackets "[" or "]" mean that value is included.`}
              tooltipOptions={{ position: 'bottom' }}
              onChange={(e) => updateOutput(e.target)}
            />
          </span>
          <InputNumber
            disabled={!isEditable}
            value={valueMin}
            id="min"
            inputId="min"
            maxFractionDigits={maxFractionDigits}
            onValueChange={(e) => updateOutput(e.target)}
            autoComplete="off"
          />
        </div>
        {sliderValues.includes(styleSelect?.code) && minValueEmpty && (
          <Message
            className="p-mt-2 p-text-left custom"
            severity="error"
            text="The minimum value field is mandatory."
          />
        )}
      </div>
      <div className="p-col-12 p-md-6">
        <div className="p-inputgroup">
          <Button label="Max Value" />
          <InputNumber
            disabled={!isEditable}
            value={valueMax}
            id="max"
            inputId="max"
            maxFractionDigits={maxFractionDigits}
            onValueChange={(e) => updateOutput(e.target)}
          />
          <span className="p-inputgroup-addon">
            <MultiStateCheckbox
              id="max_inclusive"
              disabled={!isEditable}
              value={maxInclusive}
              options={maxOptions}
              optionValue="value"
              tooltip={`Parentheses "(" or ")" means that value is excluded, whereas brackets "[" or "]" mean that value is included.`}
              tooltipOptions={{ position: 'bottom' }}
              onChange={(e) => updateOutput(e.target)}
            />
          </span>
        </div>
        {sliderValues.includes(styleSelect?.code) && maxValueEmpty && (
          <Message
            className="p-mt-2 p-text-left custom"
            severity="error"
            text="The maximum value field is mandatory."
          />
        )}
      </div>
    </>
  );
};

export default Range;
