import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import Logo from './components/Logo';

const AppMenu = ({ onMenuClick }) => {
  const { t } = useTranslation();

  return (
    <div className="layout-sidebar" role="button" tabIndex="0" onClick={onMenuClick}>
      <div className="logo">
        <NavLink to="/">
          <Logo variant="white" size="small" />
        </NavLink>
      </div>

      <div className="layout-menu-container">
        <ul className="layout-menu" role="menu">
          <li className="layout-root-menuitem" role="menuitem">
            <div className="layout-root-menuitem">
              <div className="layout-menuitem-root-text">{t('DASHBOARD')}</div>
            </div>
            <ul className="layout-menu" role="menu">
              <li className="p-mb-2" role="menuitem">
                <NavLink to="/" activeClassName="p-button" exact>
                  <i className="layout-menuitem-icon fad fa-columns" />
                  <span className="layout-menuitem-text">Questionnaires</span>
                </NavLink>
              </li>
              <li className="p-mb-2" role="menuitem">
                <NavLink to="/vocabularies" activeClassName="p-button" exact>
                  <i className="layout-menuitem-icon fad fa-ballot-check" />
                  <span className="layout-menuitem-text">Choice Lists</span>
                </NavLink>
              </li>
              <li className="p-mb-2" role="menuitem">
                <NavLink to="/blocks" activeClassName="p-button" exact>
                  <i className="layout-menuitem-icon fad fa-book-copy" />
                  <span className="layout-menuitem-text">My Library</span>
                </NavLink>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AppMenu;
