import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { confirmPopup } from 'primereact/confirmpopup';
import { DataTable } from 'primereact/datatable';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ImportQuestionnaireDialog from '../components/dialogs/ImportQuestionnaire';
import TransferOwnershipDialog from '../components/dialogs/TransferOwnershipDialog';
import {
  cloneQuestionnaire,
  deleteQuestionnaire as removeQuestionnaire,
  getQuestionnaires,
} from '../services/questionnaires';

const Dashboard = () => {
  const [loadingMyQuestionnaires, setLoadingMyQuestionnaires] = useState(true);
  const [myQuestionnaires, setMyQuestionnaires] = useState(null);
  const [sharedQuestionnaires, setSharedQuestionnaires] = useState(null);
  const [transferOwnershipDialogOpen, setTransferOwnershipDialogOpen] = useState(false);
  const [importQuestionnaireDialogOpen, setImportQuestionnaireDialogOpen] = useState(false);
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState(null);

  const [selectedMyQuestionnaires, setSelectedMyQuestionnaires] = useState(null);
  const [globalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);

  const history = useHistory();

  useEffect(() => {
    loadMyQuestionnaires();
  }, []); // eslint-disable-line

  const loadMyQuestionnaires = () => {
    setLoadingMyQuestionnaires(true);
    getQuestionnaires()
      .then(({ data }) => {
        if (data && data.length) {
          setMyQuestionnaires(data.filter((q) => q.role === 'owner'));
          setSharedQuestionnaires(data.filter((q) => q.role === 'viewer'));
          setLoadingMyQuestionnaires(false);
        } else {
          setMyQuestionnaires([]);
          setSharedQuestionnaires([]);
          setLoadingMyQuestionnaires(false);
        }
      })
      .catch(console.error);
  };

  const openNew = () => {
    history.push('/create-questionnaire');
  };

  const reloadMyQuestionnaires = () => {
    loadMyQuestionnaires();
  };

  const leftToolbarTemplate = () => (
    <>
      <Button
        label="New"
        icon="fa-duotone fa-plus"
        className="p-button-success p-mr-2"
        onClick={openNew}
      />
      <Button
        label="Import from XLSX Form"
        icon="fa-duotone fa-cloud-arrow-up"
        className="p-button-info p-mr-2"
        onClick={() => setImportQuestionnaireDialogOpen(true)}
      />
    </>
  );

  const rightToolbarTemplate = () => (
    <Button
      label="Refresh"
      icon="fa-duotone fa-arrow-rotate-right"
      className="p-button-secondary"
      onClick={reloadMyQuestionnaires}
    />
  );

  const loadQuestionnaire = (id) => {
    history.push({
      pathname: `/edit-questionnaire/${id}`,
    });
  };

  const deleteQuestionnaire = (qs) => {
    removeQuestionnaire(qs.id)
      .then(() => {
        // eslint-disable-next-line consistent-return
        const filtered = myQuestionnaires.filter((item) => item.id !== qs.id);
        setMyQuestionnaires([...filtered]);
        setSelectedMyQuestionnaires([]);
      })
      .catch((data) => console.error(data));
  };

  const clone = (id) => {
    cloneQuestionnaire(id)
      .then(() => reloadMyQuestionnaires())
      .then(() =>
        toast.current.show({
          severity: 'info',
          summary: 'Questionnaire has been cloned successfully.',
          detail: '',
          life: 3000,
        })
      )
      .catch((err) => {
        console.error(err);
        toast.current.show({
          severity: 'error',
          summary: 'A problem occurred while trying to clone questionnaire.',
          detail: '',
          life: 3000,
        });
      });
  };

  const transferOwnershipDialogResultHandle = (toastObj) => {
    setTransferOwnershipDialogOpen(false);
    toast.current.show(toastObj);
    reloadMyQuestionnaires();
  };

  const actionBodyTemplate = (rowData) => {
    const { id, role } = rowData;

    const accept = () => {
      setLoadingMyQuestionnaires(true);
      deleteQuestionnaire(rowData);
      setLoadingMyQuestionnaires(false);
    };

    return (
      <div className="actions p-text-right">
        {role === 'owner' ? (
          <>
            <Button
              icon="fa-duotone fa-pen-to-square"
              className="p-button-success p-mr-2"
              tooltip="Edit"
              tooltipOptions={{ position: 'top' }}
              onClick={() => loadQuestionnaire(rowData.id)}
            />
            <Button
              icon="fa-duotone fa-user-group-crown"
              className="p-button-secondady p-mr-2"
              tooltip="Change ownership"
              tooltipOptions={{ position: 'top' }}
              onClick={() => {
                setSelectedQuestionnaire(rowData.id);
                setTransferOwnershipDialogOpen(true);
              }}
            />
            <Button
              id={`delete_button_${id}`}
              icon="fa-duotone fa-trash"
              tooltip="Delete"
              tooltipOptions={{ position: 'top' }}
              className="p-button-danger"
              onClick={(e) => {
                confirmPopup({
                  target: e.currentTarget,
                  message: 'Are you sure you want to delete the questionnaire?',
                  icon: 'pi pi-exclamation-triangle',
                  accept: () => accept(),
                });
              }}
            />
          </>
        ) : (
          <Button
            icon="fa-duotone fa-eye"
            tooltip="View"
            tooltipOptions={{ position: 'top' }}
            className="p-button-info"
            onClick={() => loadQuestionnaire(rowData.id)}
          />
        )}
        <Button
          icon="fa-duotone fa-copy"
          tooltip="Duplicate"
          tooltipOptions={{ position: 'top' }}
          className="p-button-info p-ml-2"
          onClick={() => clone(rowData.id)}
        />
      </div>
    );
  };

  const template = (options) => {
    const toggleIcon = options.collapsed
      ? 'fa-duotone fa-chevron-down'
      : 'fa-duotone fa-chevron-up';
    const className = `${options.className} justify-content-start`;
    const titleClassName = `${options.titleClassName} pl-1`;

    return (
      <div className={className}>
        <Button className={options.togglerClassName} onClick={options.onTogglerClick}>
          <span className={toggleIcon} />
          <Ripple />
        </Button>
        <span className={titleClassName}>{options.props.header}</span>
      </div>
    );
  };

  return (
    <>
      <TransferOwnershipDialog
        dialogOpen={transferOwnershipDialogOpen}
        setDialogOpen={setTransferOwnershipDialogOpen}
        handleResult={transferOwnershipDialogResultHandle}
        questionnaireId={selectedQuestionnaire}
      />
      <ImportQuestionnaireDialog
        dialogOpen={importQuestionnaireDialogOpen}
        setDialogOpen={setImportQuestionnaireDialogOpen}
      />
      <div className="p-grid">
        <Toast ref={toast} />
        <Splitter layout="vertical" style={{ width: '100%' }}>
          <SplitterPanel size={20}>
            <div className="p-col-12">
              <Panel header="My Questionnaires" toggleable headerTemplate={template}>
                <Toolbar
                  className="p-mb-4"
                  left={leftToolbarTemplate}
                  right={rightToolbarTemplate}
                />
                <DataTable
                  ref={dt}
                  value={myQuestionnaires}
                  selection={selectedMyQuestionnaires}
                  onSelectionChange={(e) => setSelectedMyQuestionnaires(e.value)}
                  dataKey="id"
                  className="datatable-responsive"
                  globalFilter={globalFilter}
                  emptyMessage="No questionnaires found."
                  loading={loadingMyQuestionnaires}
                  selectionMode="checkbox"
                >
                  <Column field="title" header="Title" sortable />
                  <Column field="language" header="Language" sortable />
                  <Column field="version" header="Version" sortable />
                  <Column body={actionBodyTemplate} />
                </DataTable>
              </Panel>
            </div>
          </SplitterPanel>
          <SplitterPanel size={20}>
            <div className="p-col-12">
              <Panel header="Shared by me" toggleable headerTemplate={template}>
                <DataTable
                  ref={dt}
                  value={sharedQuestionnaires}
                  selection={[]}
                  dataKey="id"
                  className="datatable-responsive"
                  globalFilter={globalFilter}
                  emptyMessage="No questionnaires found."
                >
                  <Column field="title" header="Title" sortable />
                  <Column field="language" header="Language" sortable />
                  <Column field="version" header="Version" sortable />
                  <Column body={actionBodyTemplate} />
                </DataTable>
              </Panel>
            </div>
          </SplitterPanel>
        </Splitter>
      </div>
    </>
  );
};

export default Dashboard;
