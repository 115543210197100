import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

const Variable = ({ enabled, isEditable, valueVariable, updateOutput }) => {
  if (!enabled) {
    return null;
  }

  return (
    <div className="p-col-12">
      <div className="p-inputgroup">
        <Button label="Variable name" />
        <InputText
          disabled={!isEditable}
          autoComplete="off"
          value={valueVariable}
          id="variable"
          placeholder=""
          onChange={(e) => updateOutput(e.target)}
        />
      </div>
    </div>
  );
};

export default Variable;
