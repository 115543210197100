import { Checkbox } from 'primereact/checkbox';
import React from 'react';

const ReadOnly = ({ enabled, isEditable, readOnly, setReadOnly, required, setRequired }) => {
  if (!enabled) {
    return null;
  }

  return (
    <div className="p-col-12 p-md-6">
      <div className="p-col-12">
        <Checkbox
          disabled={!isEditable}
          inputId="readonly"
          checked={readOnly}
          onChange={(e) => setReadOnly(e.checked)}
        />
        <label htmlFor="readonly" style={{ paddingLeft: '10px' }}>
          {readOnly ? 'Read-only' : 'Read-only'}
        </label>
      </div>
      <div className="p-col-12">
        <Checkbox
          disabled={!isEditable}
          inputId="required"
          checked={required}
          onChange={(e) => setRequired(e.checked)}
        />
        <label htmlFor="required" style={{ paddingLeft: '10px' }}>
          {required ? 'Required' : 'Required'}
        </label>
      </div>
    </div>
  );
};

export default ReadOnly;
