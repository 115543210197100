import { nanoid } from 'nanoid';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

const RESERVED_KEYS = ['id', 'required', 'custom', 'extraLabels'];

const VocabularyChoicesTable = ({
  choices,
  setChoices,
  defaultColumns,
  columns,
  setColumns,
  language,
}) => {
  const [editingRows, setEditingRows] = useState({});
  const [newCol, setNewCol] = useState('');
  const hasAddedChoice = useRef(false);
  const toast = useRef(null);

  const choiceNames = useMemo(
    () => choices.map(({ required }) => required.find((c) => c.header === 'name').value),
    [choices]
  );

  // The custom label for showing the language name next to the label.
  const customLabelHeader = language ? `label::${language}` : 'label';

  const deleteChoice = (id) => {
    const filtered = choices.filter(({ id: choiceId }) => choiceId !== id);
    setChoices(filtered);
  };

  const deleteLastColumn = () => {
    const lastColumn = columns[columns.length - 1];

    // If there is no last column or undefined.
    if (!lastColumn) {
      return;
    }

    // We don't want to delete the default columns.
    if (defaultColumns.some((c) => c.toLowerCase() === lastColumn.toLowerCase())) {
      return;
    }

    const newCols = columns.filter((c) => c.toLowerCase() !== lastColumn.toLowerCase());
    const newChoices = choices.map((c) =>
      Object.assign(c, {
        custom: c.custom.filter((col) => col.header !== lastColumn),
        extraLabels: c.extraLabels.filter((col) => col.header !== lastColumn),
      })
    );
    setChoices(newChoices);
    setColumns(newCols);
  };

  // When to prevent adding a new column.
  const preventAddColumn = () => {
    if (newCol.length === 0) {
      return true;
    }

    if (columns.some((s) => s.toLowerCase() === newCol.toLowerCase())) {
      return true;
    }

    if (newCol === customLabelHeader) {
      return true;
    }

    return false;
  };

  const leftOptions = () => (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (!preventAddColumn()) {
          setColumns((cols) => [...cols, newCol]);
          setNewCol('');
        }
      }}
    >
      <div className="p-grid p-formgrid p-fluid">
        <div className="p-col-8 p-pb-0 p-pl-3 p-pr-1">
          <div className="p-inputgroup">
            <Button
              label="New column"
              tooltip="If needed, you may create a new column to add a label in another language e.g. label::Spanish (es) or a choice filter."
              tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            />
            <InputText
              value={newCol}
              id="new_col"
              onChange={(e) => setNewCol(e.target.value)}
              autoComplete="off"
            />
          </div>
        </div>
        <div className="p-col-2 p-px-1">
          <Button
            label="Add"
            disabled={preventAddColumn()}
            icon="fa-duotone fa-plus"
            style={{ width: '100%' }}
            className="p-button-success"
            type="submit"
          />
        </div>
        <div className="p-col-2 p-px-0">
          <Button
            label="Delete"
            style={{ width: '100%' }}
            icon="fa-duotone fa-trash"
            className="p-button-danger"
            tooltip="Delete last column"
            onClick={deleteLastColumn}
          />
        </div>
      </div>
    </form>
  );

  const rightOptions = () => (
    <div className="p-fluid p-formgrid p-grid">
      <div className="p-field p-col p-mb-0" style={{ display: 'flex' }}>
        <Button
          label="Add New Choice"
          icon="fa-duotone fa-plus"
          className="p-button-success"
          onClick={() => {
            const newChoice = {
              id: nanoid(),
              required: defaultColumns.map((c) => ({ header: c, value: '' })),
              custom: columns
                .filter((c) => !defaultColumns.includes(c) && c.indexOf('::') === -1)
                .map((c) => ({ header: c, value: '' })),
              extraLabels: columns
                .filter((c) => !defaultColumns.includes(c) && c.indexOf('::') !== -1)
                .map((c) => ({ header: c, value: '' })),
            };
            hasAddedChoice.current = true;
            setChoices((oldChoices) => [newChoice, ...oldChoices]);
          }}
        />
      </div>
    </div>
  );

  const handleRowEditComplete = (e) => {
    const { newData, index } = e;
    if (newData?.name?.length > 0 && choiceNames.includes(newData?.name)) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'The specified choice name already exists!',
        life: 5000,
      });
      hasAddedChoice.current = false;
      deleteChoice(newData.id);
      return;
    }
    const tempChoices = [...choices];
    const requiredValues = [];
    const customValues = [];
    const extraLabelValues = [];
    Object.keys(newData).forEach((k) => {
      if (RESERVED_KEYS.includes(k)) return;
      if (defaultColumns.includes(k)) {
        requiredValues.push({ header: k, value: newData[k] });
      } else if (k.indexOf('::') !== -1) {
        extraLabelValues.push({ header: k, value: newData[k] });
      } else if (k.indexOf('::') === -1) {
        customValues.push({ header: k, value: newData[k] });
      }
    });
    tempChoices[index] = {
      id: newData.id,
      required: requiredValues,
      custom: customValues,
      extraLabels: extraLabelValues,
    };
    setChoices(tempChoices);
  };

  const setActiveRowIndex = useCallback(
    (index) => {
      setEditingRows({
        ...editingRows,
        ...{ [`${choices[index].id}`]: true },
      });
    },
    [choices, editingRows]
  );

  const textEditor = (options) => {
    const { field, value } = options;

    return (
      <InputText
        type="text"
        value={value}
        className={
          field === 'name' && value?.length > 0 && choiceNames.includes(value) && 'p-invalid'
        }
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  useEffect(() => {
    if (hasAddedChoice.current) {
      setActiveRowIndex(0);
      hasAddedChoice.current = false;
    }
  }, [choices.length, setActiveRowIndex]); // eslint-disable-line

  useEffect(() => {
    if (Object.keys(editingRows).length === 0) {
      // editing is done check if first choice is empty
      if (
        choices[0]?.required[0]?.value === '' ||
        choices[0]?.required[1]?.value === '' ||
        choices[0]?.required[2]?.value === ''
      ) {
        deleteChoice(choices[0]?.id);
      }
    }
  }, [editingRows]); // eslint-disable-line

  const flattenedChoices = choices.map((choice) => ({
    ...choice,
    ...choice.required.reduce((acc, val) => ({ ...acc, [val.header]: val.value }), {}),
    ...choice.custom.reduce((acc, val) => ({ ...acc, [val.header]: val.value }), {}),
    ...choice.extraLabels.reduce((acc, val) => ({ ...acc, [val.header]: val.value }), {}),
  }));

  return (
    <div className="p-col-12">
      <Toast ref={toast} />
      <div className="card">
        <h5>Choices</h5>
        <Message
          className="p-mb-3"
          severity="warn"
          text="Make sure to use unique terms for names within the choice lists, otherwise ODK forms will not work."
        />
        <Toolbar className="p-mb-3" left={leftOptions} right={rightOptions} />
        <DataTable
          value={flattenedChoices}
          paginator
          editMode="row"
          rows={10}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50]}
          dataKey="id"
          rowHover
          responsiveLayout="scroll"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} choices"
          emptyMessage="No choices found."
          size="small"
          onRowEditComplete={handleRowEditComplete}
          editingRows={editingRows}
          onRowEditChange={(e) => setEditingRows(e.data)}
        >
          {columns.map((col) => (
            <Column
              key={col}
              field={col}
              header={col === 'label' ? customLabelHeader : col}
              editor={(options) => textEditor(options)}
            />
          ))}
          <Column rowEditor headerStyle={{ width: '6rem' }} bodyStyle={{ textAlign: 'center' }} />
          <Column
            headerStyle={{ width: '2rem' }}
            bodyStyle={{ textAlign: 'center' }}
            body={(props) => (
              <Button
                className="p-link p-button-text p-button-icon-only p-button-danger"
                icon="pi pi-trash"
                onClick={() => deleteChoice(props.id)}
              />
            )}
          />
        </DataTable>
      </div>
    </div>
  );
};

export default VocabularyChoicesTable;
