const getQuestionConfig = () => ({
  media: {
    general_properties: {
      header: 'Create Media Question',
      id: 'media',
    },
    basic_properties: {
      note: {
        enabled: false,
      },
      variable: {
        enabled: false,
      },
      datetype: {
        enabled: false,
      },
      label: {
        enabled: true,
      },
      name: {
        enabled: true,
      },
      hint: {
        enabled: true,
      },
      default: {
        enabled: true,
      },
      repeating: {
        enabled: false,
      },
      required: {
        enabled: true,
      },
      read_only: {
        enabled: true,
      },
      multiple: {
        enabled: false,
      },
      calendar: {
        enabled: false,
      },
      range: {
        enabled: false,
      },
      type: {
        unit: false,
        enabled: true,
        dropdownValues: [
          { name: 'Image', code: 'IMG' },
          { name: 'Audio', code: 'ADI' },
          { name: 'Video', code: 'VDI' },
        ],
      },
      style: {
        enabled: false,
        dropdownValues: null,
      },
      datatable: {
        enabled: false,
      },
    },
    advanced_properties: {
      hide: false,
      constraint: {
        enabled: true,
      },
      condition: {
        enabled: true,
      },
      calculation: {
        enabled: true,
      },
    },
  },
  barcode: {
    general_properties: {
      header: 'Create Barcode Question',
      id: 'barcode',
    },
    basic_properties: {
      note: {
        enabled: false,
      },
      variable: {
        enabled: false,
      },
      datetype: {
        enabled: false,
      },
      label: {
        enabled: true,
      },
      name: {
        enabled: true,
      },
      hint: {
        enabled: true,
      },
      default: {
        enabled: true,
      },
      repeating: {
        enabled: false,
      },
      required: {
        enabled: true,
      },
      read_only: {
        enabled: true,
      },
      multiple: {
        enabled: false,
      },
      range: {
        enabled: false,
      },
      calendar: {
        enabled: false,
      },
      type: {
        enabled: false,
        dropdownValues: null,
      },
      style: {
        enabled: false,
        dropdownValues: null,
      },
      datatable: {
        enabled: false,
      },
    },
    advanced_properties: {
      hide: false,
      constraint: {
        enabled: true,
      },
      condition: {
        enabled: true,
      },
      calculation: {
        enabled: true,
      },
    },
  },
  calendar: {
    general_properties: {
      header: 'Create Calendar Question',
      id: 'calendar',
    },
    basic_properties: {
      note: {
        enabled: false,
      },
      variable: {
        enabled: false,
      },
      datetype: {
        enabled: true,
      },
      label: {
        enabled: true,
      },
      name: {
        enabled: true,
      },
      hint: {
        enabled: true,
      },
      default: {
        enabled: true,
      },
      repeating: {
        enabled: false,
      },
      required: {
        enabled: true,
      },
      read_only: {
        enabled: true,
      },
      multiple: {
        enabled: false,
      },
      range: {
        enabled: false,
      },
      calendar: {
        enabled: true,
      },
      type: {
        enabled: false,
        dropdownValues: [],
      },
      style: {
        enabled: true,
        dropdownValues: [
          { name: 'Full Datetime', code: 'FTT' },
          { name: 'Full Date Selection on Calendar', code: 'FDD' },
          { name: 'Full Date Selection in Spinner', code: 'FDT' },
          { name: 'Month & Year', code: 'YAM' },
          { name: 'Year', code: 'YER' },
          { name: 'Coptic Calendar', code: 'CPT' },
          { name: 'Ethiopian Calendar', code: 'ETH' },
          { name: 'Islamic Calendar', code: 'ISM' },
          { name: 'Bikram Sambat Calendar', code: 'BKS' },
          { name: 'Myanmar Calendar', code: 'MYA' },
          { name: 'Persian Calendar', code: 'PRS' },
        ],
      },
      datatable: {
        enabled: false,
      },
    },
    advanced_properties: {
      constraint: {
        enabled: true,
      },
      condition: {
        enabled: true,
      },
      calculation: {
        enabled: true,
      },
    },
  },
  location: {
    general_properties: {
      header: 'Create Location Question',
      id: 'location',
    },
    basic_properties: {
      note: {
        enabled: false,
      },
      variable: {
        enabled: false,
      },
      datetype: {
        enabled: false,
      },
      label: {
        enabled: true,
      },
      name: {
        enabled: true,
      },
      hint: {
        enabled: true,
      },
      default: {
        enabled: true,
      },
      repeating: {
        enabled: false,
      },
      required: {
        enabled: true,
      },
      read_only: {
        enabled: true,
      },
      multiple: {
        enabled: false,
      },
      range: {
        enabled: false,
      },
      calendar: {
        enabled: false,
      },
      type: {
        enabled: true,
        dropdownValues: [
          { name: 'Point', code: 'PNT' },
          { name: 'Path', code: 'PTH' },
          { name: 'Shape', code: 'SHP' },
        ],
      },
      style: {
        enabled: true,
        dropdownValues: [
          { name: 'Default (GPS): Device GPS (no map)', code: 'DPS' },
          { name: 'Show maps (GPS): Device GPS (confirm on a map)', code: 'SPS' },
          { name: 'Manual (no GPS): No GPS (point on a map)', code: 'MPS' },
        ],
      },
      datatable: {
        enabled: false,
      },
    },
    needs_semantic_terms: true,
    advanced_properties: {
      constraint: {
        enabled: true,
      },
      condition: {
        enabled: true,
      },
      calculation: {
        enabled: true,
      },
    },
  },
  numeric: {
    general_properties: {
      header: 'Create Numeric Question',
      id: 'numeric',
    },
    basic_properties: {
      note: {
        enabled: false,
      },
      variable: {
        enabled: false,
      },
      datetype: {
        enabled: false,
      },
      label: {
        enabled: true,
      },
      name: {
        enabled: true,
      },
      required: {
        enabled: true,
      },
      read_only: {
        enabled: true,
      },
      hint: {
        enabled: true,
      },
      default: {
        enabled: true,
      },
      repeating: {
        enabled: false,
      },
      multiple: {
        enabled: false,
      },
      range: {
        enabled: true,
      },
      calendar: {
        enabled: false,
      },
      type: {
        unit: true,
        enabled: true,
        dropdownValues: [
          { name: 'Integer', code: 'INT' },
          { name: 'Decimal', code: 'DCM' },
        ],
      },
      style: {
        enabled: true,
        dropdownValues: [
          { name: 'Textbox', code: 'TXT' },
          { name: 'Slider', code: 'SLR' },
          { name: 'Vertical Slider', code: 'VLR' },
          { name: 'Picker', code: 'PKR' },
        ],
      },
      datatable: {
        enabled: false,
      },
    },
    needs_semantic_terms: true,
    advanced_properties: {
      constraint: {
        enabled: true,
      },
      condition: {
        enabled: true,
      },
      calculation: {
        enabled: true,
      },
    },
  },
  selection: {
    general_properties: {
      header: 'Create Select Question',
      id: 'selection',
    },
    basic_properties: {
      note: {
        enabled: false,
      },
      variable: {
        enabled: false,
      },
      datetype: {
        enabled: false,
      },
      label: {
        enabled: true,
      },
      name: {
        enabled: true,
      },
      hint: {
        enabled: true,
      },
      default: {
        enabled: true,
      },
      repeating: {
        enabled: false,
      },
      required: {
        enabled: true,
      },
      read_only: {
        enabled: true,
      },
      range: {
        enabled: false,
      },
      calendar: {
        enabled: false,
      },
      multiple: {
        enabled: true,
      },
      type: {
        enabled: false,
        dropdownValues: [],
      },
      style: {
        enabled: true,
        dropdownValues: [
          { name: 'Default', code: 'DFT' },
          { name: 'Dropdown Box', code: 'MNM' },
          { name: 'Auto-advance to next question', code: 'QCK' },
          { name: 'Search box with autocomplete', code: 'ATE' },
          { name: 'Likert Scale', code: 'LKR' },
        ],
      },
      datatable: {
        enabled: true,
      },
    },
    needs_semantic_terms: true,
    advanced_properties: {
      constraint: {
        enabled: true,
      },
      condition: {
        enabled: true,
      },
      calculation: {
        enabled: true,
      },
      choice_filter: {
        enabled: true,
      },
    },
  },
  text: {
    general_properties: {
      header: 'Create Text Question',
      id: 'text',
    },
    basic_properties: {
      note: {
        enabled: false,
      },
      variable: {
        enabled: false,
      },
      datetype: {
        enabled: false,
      },
      label: {
        enabled: true,
      },
      name: {
        enabled: true,
      },
      hint: {
        enabled: true,
      },
      default: {
        enabled: true,
      },
      repeating: {
        enabled: false,
      },
      required: {
        enabled: true,
      },
      read_only: {
        enabled: true,
      },
      multiple: {
        enabled: false,
      },
      range: {
        enabled: false,
      },
      calendar: {
        enabled: false,
      },
      type: {
        enabled: false,
        dropdownValues: [],
      },
      style: {
        enabled: false,
        dropdownValues: [],
      },
      datatable: {
        enabled: false,
      },
    },
    needs_semantic_terms: true,
    advanced_properties: {
      constraint: {
        enabled: true,
      },
      condition: {
        enabled: true,
      },
      calculation: {
        enabled: true,
      },
    },
  },
  group: {
    general_properties: {
      header: 'Create a group of questions',
      id: 'group',
    },
    basic_properties: {
      note: {
        enabled: false,
      },
      variable: {
        enabled: false,
      },
      datetype: {
        enabled: false,
      },
      label: {
        enabled: true,
      },
      name: {
        enabled: true,
      },
      hint: {
        enabled: false,
      },
      default: {
        enabled: false,
      },
      repeating: {
        enabled: true,
      },
      required: {
        enabled: false,
      },
      read_only: {
        enabled: false,
      },
      multiple: {
        enabled: false,
      },
      range: {
        enabled: false,
      },
      calendar: {
        enabled: false,
      },
      type: {
        enabled: false,
        dropdownValues: [],
      },
      style: {
        enabled: true,
        dropdownValues: [
          { name: 'Each question in the group appears on a separate screen', code: 'DFT' },
          { name: 'All questions in the  begin group appear on the same screen', code: 'FLT' },
        ],
      },
      datatable: {
        enabled: false,
      },
    },
    advanced_properties: {
      constraint: {
        enabled: false,
      },
      condition: {
        enabled: true,
      },
      calculation: {
        enabled: false,
      },
    },
  },
  calculation: {
    general_properties: {
      header: 'Create Calculation Question',
      id: 'calculation',
    },
    basic_properties: {
      note: {
        enabled: false,
      },
      variable: {
        enabled: true,
      },
      datetype: {
        enabled: false,
      },
      calculation: {
        enabled: true,
      },
      label: {
        enabled: false,
      },
      name: {
        enabled: false,
      },
      hint: {
        enabled: false,
      },
      default: {
        enabled: false,
      },
      repeating: {
        enabled: false,
      },
      required: {
        enabled: false,
      },
      read_only: {
        enabled: false,
      },
      multiple: {
        enabled: false,
      },
      range: {
        enabled: false,
      },
      calendar: {
        enabled: false,
      },
      type: {
        enabled: false,
        dropdownValues: [],
      },
      style: {
        enabled: false,
        dropdownValues: [],
      },
      datatable: {
        enabled: false,
      },
    },
    advanced_properties: {
      constraint: {
        enabled: false,
      },
      condition: {
        enabled: false,
      },
      calculation: {
        enabled: false,
      },
    },
  },
  url: {
    general_properties: {
      header: 'Create URL Question',
      id: 'url',
    },
    basic_properties: {
      note: {
        enabled: false,
      },
      variable: {
        enabled: false,
      },
      datetype: {
        enabled: false,
      },
      label: {
        enabled: true,
      },
      name: {
        enabled: true,
      },
      hint: {
        enabled: true,
      },
      default: {
        enabled: true,
      },
      repeating: {
        enabled: false,
      },
      required: {
        enabled: false,
      },
      read_only: {
        enabled: false,
      },
      multiple: {
        enabled: false,
      },
      range: {
        enabled: false,
      },
      calendar: {
        enabled: false,
      },
      type: {
        enabled: false,
        dropdownValues: [],
      },
      style: {
        enabled: false,
        dropdownValues: [],
      },
      datatable: {
        enabled: false,
      },
    },
    advanced_properties: {
      hide: true,
      constraint: {
        enabled: false,
      },
      condition: {
        enabled: false,
      },
      calculation: {
        enabled: false,
      },
    },
  },
});

export default getQuestionConfig;
