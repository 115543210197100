import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import RequiredField from '../../RequiredField';

const ExcelName = ({
  enabled,
  questionId,
  valueName,
  updateOutput,
  requiredFields,
  isEditable,
}) => {
  if (!enabled) {
    return null;
  }

  return (
    <div className="p-col-12 p-md-6">
      <div className="p-inputgroup">
        <Button
          tooltip={
            questionId === 'group'
              ? 'Name of the group in short'
              : 'Name of the column in exported data sheet, in words'
          }
          tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
        >
          <RequiredField />
          {questionId === 'group' ? 'Group Name' : 'Data Column Name'}
        </Button>
        <InputText
          disabled={!isEditable}
          keyfilter="alphanum"
          autoComplete="off"
          value={valueName}
          id="name"
          placeholder=""
          onChange={(e) => updateOutput(e.target)}
        />
      </div>
      {requiredFields?.includes('excel') && valueName?.length === 0 && (
        <Message
          className="p-mt-2 p-text-left custom"
          severity="error"
          text={`The ${questionId === 'group' ? 'excel name' : 'data column name'} is mandatory.`}
        />
      )}
    </div>
  );
};

export default ExcelName;
