import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Message } from 'primereact/message';
import { useEffect, useState } from 'react';
import TermHelperDialogForm from '../TermHelper/TermHelperDialogForm';

const DateType = ({
  enabled,
  isEditable,
  dateTypeDropdownValues,
  dateTypeSelect,
  setDateTypeSelect,
  requiredFields,
}) => {
  const [termHelperVisible, setTermHelperVisible] = useState(false);
  const [options, setOptions] = useState(dateTypeDropdownValues);

  useEffect(() => {
    setOptions(dateTypeDropdownValues);
    setDateTypeSelect(null);
  }, [dateTypeDropdownValues, setDateTypeSelect]);

  if (!enabled) {
    return null;
  }

  return (
    <>
      <div className="p-col-12 p-md-12">
        <div className="custom-label">
          <Button
            label="Date Type - Semantics"
            tooltip="Defines to what the provided date is refering."
            tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
          />
        </div>
        <div className="p-inputgroup">
          <Dropdown
            id="datetype"
            disabled={!isEditable}
            value={dateTypeSelect}
            filter
            filterBy="term"
            optionLabel="term"
            onChange={(e) => setDateTypeSelect(e.target.value)}
            options={options}
            placeholder="Select date type"
          />
        </div>
        {requiredFields.includes('datetype') && dateTypeSelect?.id?.length === 0 && (
          <Message
            className="p-mt-2 p-text-left custom"
            severity="error"
            text="The date type field is mandatory."
          />
        )}
      </div>
      <div className="p-col-12 p-my-0 p-py-0">
        <div className="p-text-right">
          <Button
            label="Use custom term"
            className="p-button-sm"
            disabled={!isEditable}
            icon="fa-duotone fa-input-text"
            style={{ width: 'auto' }}
            onClick={() => setTermHelperVisible(!termHelperVisible)}
          />
          <TermHelperDialogForm
            visible={termHelperVisible}
            vocabulary="date"
            setVisible={setTermHelperVisible}
            onCreatedTerm={(term) => {
              setOptions([...options, term]);
              setDateTypeSelect(term);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default DateType;
