import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Message } from 'primereact/message';

const Vocabularies = ({
  enabled,
  isEditable,
  vocabularies,
  vocabularySelect,
  requiredFields,
  updateOutput,
}) => {
  if (!enabled) {
    return null;
  }

  return (
    <>
      <div className="p-col-12 p-md-6">
        <div className="custom-label">
          <Button label="Choice List" />
        </div>
        <Dropdown
          id="vocabulary"
          disabled={!isEditable}
          value={vocabularySelect}
          onChange={(e) => updateOutput(e.target)}
          options={vocabularies.map((v) => ({
            listname: v?.body?.listname || v?.listname,
            value: v?.body?.id || v?.id,
          }))}
          optionLabel="listname"
          placeholder="Select choice list"
        />
        {requiredFields.includes('vocabulary') && vocabularySelect?.length === 0 && (
          <Message
            className="p-mt-2 p-text-left custom"
            severity="error"
            text="The choice list field is mandatory."
          />
        )}
      </div>
    </>
  );
};

export default Vocabularies;
