import { nanoid } from 'nanoid';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { createQuestionnaire, importQuestionnaire } from '../../services/questionnaires';
import {
  createVocabulary,
  getMultipleVocabularies,
  getVocabularies,
  updateVocabulary,
} from '../../services/vocabularies';
import { ToastContext } from '../../store';
import { handleError } from '../../utilities/errors';

const ImportQuestionnaireDialog = ({ dialogOpen, setDialogOpen }) => {
  const [loaded, setLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [questionnaire, setQuestionnaire] = useState({});
  const [myVocabularies, setMyVocabularies] = useState([]);
  const [updatableVocs, setUpdatableVocs] = useState([]);
  const [vocsToAdd, setVocsToAdd] = useState([]);
  const [vocsToUpdate, setVocsToUpdate] = useState([]);
  const fileUploadRef = useRef(null);
  const history = useHistory();
  const { setError, setSuccess } = useContext(ToastContext);

  const fetchVocabularies = useCallback(async () => {
    try {
      const { data } = await getVocabularies();
      setMyVocabularies(data);
    } catch (error) {
      setError(handleError(error));
    }
  }, [setError]);

  const findUpdatableVocs = useCallback(
    async (myVocs, qVocs) => {
      if (myVocs.length === 0 || qVocs?.length === 0) {
        return;
      }
      setIsLoading(true);

      const listnames = qVocs?.map((voc) => voc.listname);
      const matchingVocabularies = myVocs?.filter(
        (v) => listnames.includes(v.listname) && !v.isGlobal
      );
      const listnameMapToObject = {};
      qVocs?.forEach((v) => {
        listnameMapToObject[v.listname] = { ...v };
      });

      try {
        const ids = matchingVocabularies.map((v) => v.id);
        const { vocabularies: vocs } = await getMultipleVocabularies(ids);
        if (vocs.length > 0) {
          setUpdatableVocs(
            vocs
              .filter((v) => v.choices.length < listnameMapToObject[v.listname].choices.length)
          );
        }
      } catch (e) {
        setError(handleError(e));
      } finally {
        setIsLoading(false);
      }
    },
    [setError]
  );

  const addVocabularies = useCallback(async () => {
    try {
      setIsLoading(true);
      await Promise.all(vocsToAdd.map((v) => createVocabulary({ ...v })));
      await fetchVocabularies();
      setSuccess('Success', `Choice list has been added to your collection.`);
      setVocsToAdd([]);
    } catch (e) {
      setError(handleError(e));
    } finally {
      setIsLoading(false);
    }
  }, [fetchVocabularies, setSuccess, vocsToAdd, setError]);

  const updateVocabularies = useCallback(async () => {
    try {
      setIsLoading(true);
      const listnameMapToObject = {};
      questionnaire?.vocabularies?.forEach((v) => {
        listnameMapToObject[v.listname] = {
          ...v,
          choices: v.choices.map((c) => ({ ...c, id: nanoid() })),
        };
      });
      await Promise.all(
        vocsToUpdate.map((v) =>
          updateVocabulary(v.id, { ...v.body, ...listnameMapToObject[v.listname] })
        )
      );
      const { data } = await getVocabularies();
      setMyVocabularies(data);
      await findUpdatableVocs(data, questionnaire?.vocabularies);
      setSuccess('Success', `${vocsToUpdate.length} choice lists have been updated.`);
    } catch (e) {
      setError(handleError(e));
    } finally {
      setIsLoading(false);
    }
  }, [findUpdatableVocs, questionnaire?.vocabularies, setSuccess, vocsToUpdate, setError]);

  const createFromImportedQuestionnaire = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await createQuestionnaire(questionnaire);
      history.push({ pathname: `/edit-questionnaire/${data.id}` });
      setSuccess('Success', `Questionnaire has been created.`);
    } catch (e) {
      setError(handleError(e));
    } finally {
      setIsLoading(false);
    }
  }, [questionnaire, history, setError, setSuccess]);

  const resetState = () => {
    setLoaded(false);
    setIsLoading(false);
    setMyVocabularies([]);
    setUpdatableVocs([]);
    setVocsToAdd([]);
    setVocsToUpdate([]);
  };

  const uploadAndImport = async (file) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    try {
      const { metadata, nodes, vocabularies } = await importQuestionnaire(formData);
      setLoaded(true);
      setQuestionnaire({
        nodes,
        metadata,
        vocabularies,
      });
      const { data } = await getVocabularies();
      setMyVocabularies(data);
      // await findUpdatableVocs(data, vocabularies);
    } catch (error) {
      setError(setError(handleError(error)));
    } finally {
      setIsLoading(false);
    }
  };

  const addableVocs = useMemo(() => {
    const listnames = myVocabularies.map((voc) => voc.listname);
    return questionnaire?.vocabularies?.filter((v) => !listnames.includes(v.listname));
  }, [questionnaire?.vocabularies, myVocabularies]);

  return (
    <Dialog
      header="Import Questionnaire From XLSX Form"
      visible={dialogOpen}
      style={{ width: 'auto', minWidth: '500px' }}
      draggable={false}
      closable
      maximizable
      modal
      onHide={() => {
        setDialogOpen(false);
        resetState();
      }}
    >
      <div className="p-grid">
        <div className="p-col-12 p-text-center">
          {!loaded ? (
            <>
              <input
                className="hidden"
                type="file"
                multiple={false}
                ref={fileUploadRef}
                accept=".xlsx,.xls"
                onChange={(e) => uploadAndImport(e.target.files[0])}
              />
              <Button
                label="Load file from your computer"
                icon="fa-solid fa-upload"
                loading={isLoading}
                disabled={isLoading}
                className="p-button-secondary p-mr-2 p-mb-2"
                onClick={(_e) => fileUploadRef.current.click()}
              />
            </>
          ) : (
            <>
              <p>
                Analyzed XLS/XLSX form: Found questionnaire titled&nbsp;
                <strong>&quot;{questionnaire?.metadata?.title}&quot;</strong> with&nbsp;
                <strong>{questionnaire?.nodes?.length} questions</strong> and&nbsp;
                <strong>{questionnaire?.vocabularies?.length} choice lists</strong>.
              </p>
              <DataTable
                className="p-mt-5"
                loading={isLoading}
                header={
                  <div className="p-d-flex p-jc-between p-ai-center">
                    <strong>New choice lists were found</strong>
                    <div>
                      <Button
                        label="Add selected to your choice lists"
                        icon="fa-solid fa-plus"
                        disabled={vocsToAdd.length === 0 || isLoading}
                        loading={isLoading}
                        className="p-button-info"
                        onClick={() => addVocabularies()}
                      />
                    </div>
                  </div>
                }
                value={addableVocs}
                selection={vocsToAdd}
                selectionMode="single"
                onSelectionChange={(e) => setVocsToAdd([e.value])}
              >
                <Column selectionMode="single" />
                <Column field="listname" header="List Name" />
                <Column field="description" header="Description" />
              </DataTable>
              {false && (
                <DataTable
                  className="p-mt-5"
                  loading={isLoading}
                  header={
                    <div className="p-d-flex p-jc-between p-ai-center">
                      <strong>Choice lists to update</strong>
                      <div>
                        <Button
                          label="Update selected choice lists"
                          icon="fa-solid fa-save"
                          disabled={vocsToUpdate.length === 0 || isLoading}
                          loading={isLoading}
                          className="p-button-info"
                          onClick={() => updateVocabularies()}
                        />
                      </div>
                    </div>
                  }
                  value={updatableVocs}
                  selection={vocsToUpdate}
                  selectionMode="checkbox"
                  onSelectionChange={(e) => setVocsToUpdate(e.value)}
                >
                  <Column selectionMode="multiple" />
                  <Column field="listname" header="List Name" />
                  <Column field="description" header="Description" />
                </DataTable>
              )}
              <div className="p-d-flex p-jc-end p-mt-5">
                <Button
                  label="Create Questionnaire"
                  icon="fa-solid fa-check"
                  disabled={isLoading}
                  className="p-button-success"
                  onClick={() => createFromImportedQuestionnaire()}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default ImportQuestionnaireDialog;
