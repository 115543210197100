import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import RequiredField from '../../RequiredField';

import MultilingualEntriesTable from '../MultilingualEntriesTable';

const Label = ({
  enabled,
  isEditable,
  questionId,
  valueLabel,
  updateOutput,
  requiredFields,
  availableLanguages,
  defaultLanguage,
}) => {
  if (!enabled) {
    return null;
  }

  const handleAddItem = (item) => {
    updateOutput({
      id: 'label',
      value: valueLabel ? valueLabel.concat([item]) : [item],
    });
  };

  const handleDeleteItem = (langValue) => {
    updateOutput({
      id: 'label',
      value: valueLabel ? valueLabel.filter((vl) => vl.language.code !== langValue) : [],
    });
  };

  const header = (
    <Button
      className="p-text-left"
      style={{ border: 'none', background: 'none', padding: 0 }}
      tooltip={
        questionId === 'group'
          ? 'Name of the group in natural language.'
          : 'Your question in natural language.'
      }
      tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
    >
      <RequiredField />
      {questionId === 'group' ? 'Group Label' : 'Question Label'}
    </Button>
  );

  return (
    <div className="p-col-12">
      <div>
        <MultilingualEntriesTable
          defaultLanguageCode={defaultLanguage?.code}
          className="custom-multilingual-entry"
          isEditable={isEditable}
          mode="edit"
          data={valueLabel}
          onAddItem={handleAddItem}
          onDeleteItem={handleDeleteItem}
          header={header}
          availableLanguages={availableLanguages}
        />
      </div>
      {requiredFields.includes('label') && valueLabel?.length === 0 && (
        <Message
          className="p-mt-2 p-text-left custom"
          severity="error"
          text={`The ${questionId === 'group' ? 'group' : 'question'} label is mandatory.`}
        />
      )}
    </div>
  );
};

export default Label;
